// React
import { useEffect, useRef, useState } from "react";
// Components
import { Loader } from "./Loader";
import { Sphere360 } from "../sphere360/Sphere360";
import { LandingPage } from "../landingPage/LandingPage";
import { InfoPage } from "../infoPage/InfoPage";
import { Lang } from "../lang/Lang";
import { Intro } from "../intro/Intro";
import { Menu } from "./Menu";
import { Credits } from "../credits/Credits";
// Project
import axios from "axios";
import { parseSync } from "subtitle";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers";
import { experiences } from "../../datas/experiences";
import { GetTrad } from "../Partials/GetTrad";
import moment from "moment";

export const Home = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user);

	useEffect(() => {
		if (document.location.pathname === "/stroll") {
			displayInfoPage("stroll");
		} else if (document.location.pathname === "/eats") {
			displayInfoPage("eats");
		}
	}, []);

	const [videoName, setVideoName] = useState("MARLY_STROLL_3K_FR_HIGH");
	const _videoName = useRef(videoName);

	useEffect(() => {
		_videoName.current = videoName;
	}, [videoName]);

	// eslint-disable-next-line
	const [maxSize, setMaxSize] = useState(2880);

    // Quality
	const [quality, setQuality] = useState(1);

    const toggleQuality = () => {
		console.log("Toggle Quality Trigger");

        let add = "";

        if(quality == 0){
            setQuality(1)
            add = "_HIGH"
        }else{
            setQuality(0)
        }

		let videoElement = document.getElementById("video");

		if (videoElement) {

            let path = "./assets/videos/MARLY_STROLL_3K_";

            path += language.toUpperCase();
            path += add;
            path += ".mp4";

            let currentTiming = (videoElement as HTMLVideoElement).currentTime;

            (videoElement as HTMLVideoElement).src = path;
            (videoElement as HTMLVideoElement).currentTime = currentTiming;
            (videoElement as HTMLVideoElement).play();

		}

	};


	// Sound
	const [sound, setSound] = useState(true);

	const toggleSound = () => {
		console.log("Toggle Sound Trigger");
		let videoElement = document.getElementById("video");

		if (videoElement) {
			(videoElement as HTMLVideoElement).muted = !(
				videoElement as HTMLVideoElement
			).muted;
			setSound(!sound);
		}
	};

	// Subtitles
	const [subtitles, setSubtitles] = useState(false);

	const toggleSubtitles = () => {
		console.log("Toggle Subtitles Trigger");
		let srtElem = document.getElementById("srt");

		if (srtElem) {
			srtElem.style.opacity = subtitles ? "0" : "1";
		}
		setSubtitles(!subtitles);
	};

	// Pages display
	const [langPage, displayLangPage] = useState(true);
	const [introPage, displayIntroPage] = useState(true);
	const [infoPage, displayInfoPage] = useState<string | null>(null);
	const [credits, setCredits] = useState(false);
	const [landingPage, setLandingPage] = useState<string | null>(null);

	// Loader
	const [buffered, setBuffered] = useState(0);
	const [pausedSince, setPausedSince] = useState(50);
	const [loader, setLoader] = useState(false);

	//Srt
	//const [srtData, setSrtData] = useState<NodeList | undefined>(undefined);

	// useEffect(() => {
	// 	let interval: NodeJS.Timer;
	// 	//console.log("load srt")

	// 	axios
	// 		.get(
	// 			"/assets/videos/" +
	// 				(_videoName.current.includes("EATS") ? "eats" : "aoe") +
	// 				".srt"
	// 		)
	// 		.then((res) => {
	// 			//console.log(res.data);

	// 			let nodes = parseSync(res.data);

	// 			//console.log(nodes)

	// 			interval = setInterval(function () {
	// 				var videoElement = document.querySelector("video");

	// 				if (videoElement) {
	// 					//console.log(videoElement.currentTime)

	// 					let found = null;
	// 					let currentTiming = videoElement.currentTime * 1000;

	// 					for (let n of nodes) {
	// 						if (n.type === "cue") {
	// 							if (
	// 								n.data.start < currentTiming &&
	// 								currentTiming < n.data.end
	// 							) {
	// 								found = n.data.text;
	// 							}
	// 						}
	// 					}

	// 					var srtElement = document.getElementById("srt");

	// 					if (found) {
	// 						//console.log(found);
	// 						if (srtElement && subtitles === true) {
	// 							srtElement.style.opacity = "1";
	// 							srtElement.innerHTML = found;
	// 						}
	// 					} else {
	// 						if (srtElement) {
	// 							srtElement.style.opacity = "0";
	// 							srtElement.innerHTML = "";
	// 						}
	// 					}
	// 				}
	// 			}, 100);
	// 		})
	// 		.catch((e: any) => {});

	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// }, [subtitles]);

	useEffect(() => {
		let inter = setInterval(() => {
			if (pausedSince > 0) {
				if (Date.now() - pausedSince > 1000) {
					setLoader(true);
					return;
				}
			}
			setLoader(false);
		}, 1000);

		return () => clearInterval(inter);
	});

	const displayCredits = (value: boolean) => {
		if (value) {
			let videoElement = document.getElementById("video");
			if (videoElement) {
				(videoElement as HTMLVideoElement).pause();

				let homeElement = document.getElementById("home");

				if (homeElement) {
					homeElement.style.pointerEvents = "all";
				}
			}

			let viewportElement = document.getElementById("viewport");
			if (viewportElement) {
				viewportElement.style.width = "0%";
				viewportElement.style.height = "0%";
				viewportElement.style.opacity = "0";
			}

			setCredits(true);
		} else {
			let videoElement = document.getElementById("video");
			if (videoElement && !introPage && !landingPage) {
				(videoElement as HTMLVideoElement).play();

				let homeElement = document.getElementById("home");

				if (homeElement) {
					homeElement.style.pointerEvents = "none";
				}
			}

			let viewportElement = document.getElementById("viewport");
			if (viewportElement) {
				viewportElement.style.width = "100%";
				viewportElement.style.height = "100%";
				viewportElement.style.opacity = "1";
			}

			setCredits(false);
		}
	};

	const newName = (language: string, name: string = "MARLY") => {
		//if (maxSize < 2880) {
		name += "_3K";
		/*} else {
			name += '_3K';
		}*/
		// console.log("VideoName =", name);

		name += "_" + language.toUpperCase()+"_HIGH";
		setVideoName(name);
	};

	useEffect(() => {
		newName(language);
	}, [language]);

	const [exp, setExp] = useState<string>("");

	const introDisapear = (move: string) => {
		setExp(move);
		if (move === "stroll") {
			newName(language, "MARLY_STROLL");
		} else {
			newName(language, "MARLY_STROLL");
		}
		let videoElement = document.getElementById("video");
		if (videoElement) {
			setTimeout(() => {
				setPausedSince(Date.now());

				(videoElement as HTMLVideoElement).play();

				if (subtitles) {
					let srtElem = document.getElementById("srt");

					if (srtElem) {
						srtElem.style.opacity = "1";
					}
				}
			}, 900);

			let homeElement = document.getElementById("home");

			if (homeElement) {
				homeElement.style.pointerEvents = "none";
			}

			setTimeout(() => {
				let elem = document.getElementById("viewport");

				if (elem) {
					elem.style.opacity = "1";
				}
			}, 500);
		} else {
			alert("video not ok");
		}

		setTimeout(() => {
			displayIntroPage(false);
			displayInfoPage(null);
		}, 500);
	};

	const infosApears = (move: string) => {
		let videoElement = document.getElementById("video");
		if (videoElement) {
			(videoElement as HTMLVideoElement).pause();
			setTimeout(() => {
				(videoElement as HTMLVideoElement).currentTime = 0;
			}, 100);

			if (subtitles) {
				let srtElem = document.getElementById("srt");

				if (srtElem) {
					srtElem.style.opacity = "0";
				}
			}

			let homeElement = document.getElementById("home");

			console.log("home pointer events");

			setTimeout(() => {
				let elem = document.getElementById("viewport");

				if (elem) {
					elem.style.opacity = "0";
				}
				setTimeout(() => {
					if (homeElement) {
						homeElement.style.pointerEvents = "all";
					}
				}, 100);
			}, 200);
		} else {
			alert("video not ok");
		}

		setTimeout(() => {
			displayIntroPage(true);
			displayInfoPage(move);
		}, 500);
	};

	const timerButtons = useRef(Date.now());
	const [buttons, displayButtons] = useState<boolean>(true);

	useEffect(() => {
		let interval: NodeJS.Timer;
		let timeout: NodeJS.Timeout;

		if (buttons) {
			let menuElem = document.getElementById("menu");

			if (menuElem) {
				menuElem.style.display = "flex";
				timeout = setTimeout(() => {
					if (menuElem) {
						menuElem.style.right = "0%";
						menuElem.style.opacity = "1";
					}
				}, 10);
			}
			interval = setInterval(() => {
				if (Date.now() - timerButtons.current >= 5000) {
					displayButtons(false);
				}
			}, 1000);
		} else {
			let menuElem = document.getElementById("menu");

			if (menuElem) {
				menuElem.style.right = "-20%";
				menuElem.style.opacity = "0";

				timeout = setTimeout(() => {
					if (menuElem) {
						menuElem.style.display = "none";
					}
				}, 200);
			}
		}

		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
	}, [buttons]);

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				backgroundColor: "black",
				overflowX: "hidden",
			}}
		>
			<div
				className='viewport'
				onClick={() => {
					timerButtons.current = Date.now();
					displayButtons(true);
				}}
				onDoubleClick={() => {
					let videoElement = document.getElementById(
						"video"
					) as HTMLVideoElement;
					if (videoElement) {
						if (videoElement.paused) {
							videoElement.play();
						} else {
							videoElement.pause();
						}
					}
				}}
			>
				{!langPage && <Sphere360 setMaxSize={setMaxSize} />}
			</div>

			{!introPage && !infoPage && !landingPage && !credits && (
				<Menu
                    quality={quality}
                    toggleQuality={toggleQuality}
					sound={sound}
					toggleSound={toggleSound}
					displayCredits={displayCredits}
					subtitles={subtitles}
					toggleSubtitles={toggleSubtitles}
					back={() => infosApears("stroll")}
                    language={language}
				/>
			)}

			<div id='home' className='home'>
				<video
					id='video'
					playsInline
					preload='metadata'
					width='0'
					height='0'
					src={"./assets/videos/" + videoName + ".mp4"}
					style={{
						position: "absolute",
						opacity: 0,
						margin: 0,
						padding: 0,
					}}
					onEnded={(e) => {
						let viewportElement = document.getElementById("viewport");
						if (viewportElement) {
							viewportElement.style.width = "0%";
							viewportElement.style.height = "0%";
							viewportElement.style.opacity = "0";
						}

						if (!videoName.includes("STROLL")) {
							setLandingPage("stroll");
						} else {
							setLandingPage("stroll");
						}
					}}
					onCanPlay={(e) => {
						if (!introPage && !landingPage) {
							//e.currentTarget.play();
							let homeElement = document.getElementById("home");

							if (homeElement) {
								homeElement.style.pointerEvents = "none";
							}
						}
					}}
					onWaiting={(e) => {
						setPausedSince(Date.now());

						let homeElement = document.getElementById("home");

						if (homeElement) {
							homeElement.style.pointerEvents = "all";
						}
					}}
					onPlaying={(e) => {
						setPausedSince(-1);

						let homeElement = document.getElementById("home");

						if (homeElement) {
							homeElement.style.pointerEvents = "none";
						}
					}}
					onProgress={(e) => {
						let video = e.currentTarget;

						if (video) {
							if (video.buffered.length > 0) {
								var bufferedEnd = video.buffered.end(video.buffered.length - 1);
								var duration = video.duration;
								if (duration > 0) {
									let bufferedAmount =
										document.getElementById("buffered-amount");
									if (bufferedAmount) {
										bufferedAmount.style.width =
											(bufferedEnd / duration) * 100 + "%";
										setBuffered(Math.trunc((bufferedEnd / duration) * 100));
									}
								}
							}
						}
					}}
					onPlay={() => {
						timerButtons.current = Date.now();
						displayButtons(true);
					}}
					onTimeUpdate={(e) => {
						let video = e.currentTarget;

						if (video) {
							var duration = video.duration;
							if (duration > 0) {
								let progressAmount = document.getElementById("progress-amount");
								if (progressAmount) {
									progressAmount.style.width =
										(video.currentTime / duration) * 100 + "%";
								}

								let timerElem = document.getElementById("timerElem");

								if (timerElem) {
									timerElem.innerHTML = moment
										.utc(Math.floor(video.currentTime) * 1000)
										.format("mm:ss");
								}

								let durationElem = document.getElementById("durationElem");

								if (durationElem) {
									durationElem.innerHTML = moment
										.utc(Math.floor(video.duration) * 1000)
										.format("mm:ss");
								}
							}
						}
					}}
				/>

				{/* Lang Page */}
				{!credits && introPage && langPage && (
					<Lang
						sound={sound}
						toggleSound={toggleSound}
						displayCredits={displayCredits}
						subtitles={subtitles}
						toggleSubtitles={toggleSubtitles}
						displayLangPage={displayLangPage}
					/>
				)}

				{/* Intro Page */}
				{!credits && introPage && !langPage && (
					<Intro
						sound={sound}
						toggleSound={toggleSound}
						displayCredits={displayCredits}
						subtitles={subtitles}
						toggleSubtitles={toggleSubtitles}
						back={() => document.location.reload()}
						next={introDisapear}
						infos={displayInfoPage}
					/>
				)}

				{/* Info Page */}
				{infoPage && (
					<InfoPage
						infoPage={infoPage}
						hide={() => displayInfoPage(null)}
						next={introDisapear}
						back={() => displayInfoPage(null)}
					/>
				)}

				{/* Landing Page */}
				{landingPage && (
					<LandingPage
						displayInfoPage={displayInfoPage}
						setLandingPage={setLandingPage}
						landingPage={landingPage}
						next={introDisapear}
						back={() => document.location.reload()}
					/>
				)}

				{/* Credits */}
				{credits && <Credits goBack={() => displayCredits(false)} />}
			</div>
			{!landingPage && !introPage && (
				<div className='videoInterface' style={{ pointerEvents: "none" }}>
					<div className='srt'>
						<div
							id='srt'
							style={{
								opacity: "0",
							}}
						></div>
					</div>

					<div className='progressbarContainer'>
						<div className='progressbar-content'>
							<div className='progressbar-contentTitle'>
								<GetTrad
									value={
										experiences.find((e: any) => e.id === exp)?.title.valuePart1
									}
									lang={language}
								/>
								<GetTrad
									value={
										experiences.find((e: any) => e.id === exp)?.title.valuePart2
									}
									lang={language}
								/>
							</div>
							<div className='progressbar-contentTimer'>
								<span id='timerElem'></span>
								{" / "}
								<span id='durationElem'></span>
							</div>
						</div>
						<div
							id='progressbar'
							className='progressbar'
							onClick={(e) => {
								let progressbar = document.getElementById("progressbar");

								if (progressbar) {
									var offset = progressbar.getClientRects()[0];
									let dist = e.clientX - offset.left;
									let percentage = (dist * 100) / offset.width;

									let videoElement = document.getElementById("video");
									if (videoElement) {
										(videoElement as HTMLVideoElement).currentTime =
											(percentage / 100) *
											(videoElement as HTMLVideoElement).duration;
									}
								}
							}}
							onTouchEnd={(e) => {
								let progressbar = document.getElementById("progressbar");

								if (progressbar) {
									var offset = progressbar.getClientRects()[0];
									let dist = e.changedTouches[0].clientX - offset.left - 10;
									let percentage = (dist * 100) / offset.width;

									let videoElement = document.getElementById("video");
									if (videoElement) {
										(videoElement as HTMLVideoElement).currentTime =
											(percentage / 100) *
											(videoElement as HTMLVideoElement).duration;
									}
								}
							}}
						>
							<div
								className='buffered'
								style={{
									height: "2px",
									position: "absolute",
									background: "#D9D9D9",
									width: "100%",
									bottom: "24px",
									opacity: "0.4",
								}}
							></div>
							<div
								className='progress'
								style={{
									height: "2px",
									position: "absolute",
									width: "100%",
									bottom: "24px",
									opacity: "1",
								}}
							>
								<span
									id='progress-amount'
									style={{
										display: "block",
										height: "100%",
										backgroundColor: "#fff",
										width: "0",
									}}
								></span>
							</div>
						</div>
					</div>
				</div>
			)}

			{loader && !introPage && !landingPage && <Loader buffered={buffered} />}
		</div>
	);
};

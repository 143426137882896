// Eats
import eatsTitleImg from "../style/assets/infoPage/stroll/title.png";
import strollAfficheNormalImg from "../style/assets/infoPage/stroll/affiche.jpg";
import strollAfficheBlurImg from "../style/assets/infoPage/stroll/afficheBlur.jpg";

export const experiences = [
	{
		id: "stroll2",
		title: {
			valuePart1: "strollTitlePart1",
			valuePart2: "strollTitlePart2",
			img: eatsTitleImg,
		},
		affiche: {
			normal: strollAfficheNormalImg,
			blur: strollAfficheBlurImg
		},
		content: [
			{
				type: "title",
				value: "strollSubtitle",
			},
			{
				type: "text",
				value: "strollText1",
			},
		],
		endPage: [
			{
				type: "text",
				value: "experienceProposedBy",
			},
			{
				type: 'text',
				value: 'otherTravelAOE'
			}
		],
		movie: {
			title: "eatsTitle",
			vignette: eatsTitleImg,
			new: true,
			duration: "4:38",
		},
		share: {
			header: "L'Élysée à travers les siècles.",
		},
	},
	{
		id: "stroll",
		title: {
			valuePart1: "strollTitlePart1",
			valuePart2: "strollTitlePart2",
			img: eatsTitleImg,
		},
		affiche: {
			normal: strollAfficheNormalImg,
			blur: strollAfficheBlurImg
		},
		content: [
			{
				type: "title",
				value: "strollSubtitle",
			},
			{
				type: "text",
				value: "strollText1",
			},
		],
		endPage: [
			{
				type: "text",
				value: "experienceProposedBy",
			},
		],
		movie: {
			title: "eatsTitle",
			vignette: eatsTitleImg,
			new: true,
			duration: "4:38",
		},
		share: {
			header: "strollShareHeader",
		},
	},
];

// React
import React, { useEffect, useState } from "react";
// CSS
import "./infoPage.css";
// Datas
import { experiences } from "../../datas/experiences";
// Assets
import timescopeLogo from "../../style/assets/home/logoTimescope.svg";
import { GetTrad } from "../Partials/GetTrad";
import { trads } from "../../datas/trads";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers";

export const InfoPage = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user);

	const [experience, setExperience] = useState<any>();

	useEffect(() => {
		console.log("Display InfoPage", props.infoPage);

		setExperience(experiences.find((e: any) => e.id === props.infoPage));
	}, [props.infoPage]);

	if (!experience) {
		return <></>;
	}

	return (
		<div className='infoPage'>
			<div className='infoPage-overflowed'>
				<div id='intro-header' className='intro-header'>
					<div className='intro-header-back' onClick={() => props.back()}></div>
					<div className='intro-header-logo'></div>
				</div>
				<div className='infoPage-intro'>
					<div className='infoPage-title'>
						{trads.find((t: any) => t.id === language)?.strollTitlePart1}
					</div>
					<div className='infoPage-subtitle'>
						{trads.find((t: any) => t.id === language)?.strollTitlePart2}
					</div>
				</div>

				<div className='infoPage-afficheContainer'>
					<div
						className='infoPage-affiche'
						style={{ backgroundImage: `url(${experience?.affiche.normal})` }}
						onClick={() => {
							props.next(experience.id);
						}}
					>
						<div className='infoPage-bloc-videoTrailer-playIcon btnStart'></div>
					</div>
				</div>

				<div className='infoPage-content'>
					{experience.content.map((c: any, index: number) => {
						switch (c.type) {
							case "title":
								return (
									<div
										key={"expcontent-" + index}
										className='infoPage-content-title'
									>
										<GetTrad value={c.value} lang={language} />
									</div>
								);
							case "text":
								return (
									<div
										key={"expcontent-" + index}
										className='infoPage-content-text'
									>
										<GetTrad value={c.value} lang={language} />
									</div>
								);
							default:
								return <></>;
						}
					})}
				</div>

				<div className='infoPage-footer'>
					<div
						className='infoPage-share'
						onClick={() => {
							navigator.share({
								url: "/" + experience.id,
								text: (trads.find((t: any) => t.id === language) as any)[
									experience.share.header
								],
								title: (trads.find((t: any) => t.id === language) as any)[
									experience.share.header
								],
							});
						}}
					>
						<div className='infoPage-share-icon'></div>
						<div className='infoPage-share-text'>
							{trads.find((t: any) => t.id === language)?.share.toUpperCase()}
						</div>
					</div>
					<br />
					<div className='lang-logos'>
						<div className='lang-logos-collab'>
							<GetTrad value='homeCollab' lang={language} />
						</div>
						<div
							className='lang-logos-logo'
							style={{
								backgroundImage: `url(${timescopeLogo})`,
							}}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};
